import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'

const atobUTF8 = value => {
  let parsedWordArray = Base64.parse(value);
  let parsedStr = parsedWordArray.toString(Utf8);
  return parsedStr
}

export default atobUTF8
