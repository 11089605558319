import React from 'react'
import { DiscussionEmbed } from 'disqus-react'

class Disqus extends React.Component {
    shouldRender = () => (process.env.NODE_ENV === `production`)

    render () {
      const disqusConfig = {
        url: this.props.location.href,
        identifier: this.props.location.href
      }

      if (this.shouldRender()) {
        return (
          <DiscussionEmbed shortname={this.props.disqusShortname} config={disqusConfig} />
        )
      }

      return null
    }
}

export default Disqus
